import axios from "./axios";

/*
 * Payment Endpoint:
 * Fetch Subscription Amount
 */

interface RequestContactReq {
  contactInfo: string;
}

interface RequestContactRes {
  data: {
    status: string;
  };
}

export async function sendContactReq(
  data: RequestContactReq
): Promise<RequestContactRes> {
  return axios.post("admins/send-contact-req.php", data);
}
