import { FC, useState } from "react";

import {
  VStack,
  Box,
  Heading,
  Text,
  Link,
  FormControl,
  Input,
  Button,
  Stack,
} from "@chakra-ui/react";
import { sendContactReq } from "../api/contactEndpoints";

interface ContactRequestProps {}

const ContactRequest: FC<ContactRequestProps> = () => {
  const [input, setInput] = useState("");
  const [loading, setLoading] = useState(false);
  const [requestSent, setRequestSent] = useState(false);

  const handleContactRequest = () => {
    setLoading(true);

    if (input === "") {
      return;
    }

    sendContactReq({ contactInfo: input })
      .then((response) => {
        if (response.data.status === "success") {
          setRequestSent(true);
        }

        const interval = setInterval(() => {
          setLoading(false);
        }, 1000);
        return () => clearInterval(interval);
      })
      .catch(console.log);
  };

  return (
    <VStack maxW={"100vw"} pb={"40"}>
      <Heading size={"2xl"} color={"white"} textAlign={"center"}>
        GET IN TOUCH
      </Heading>
      <Text color={"white"} textAlign={"center"}>
        For queries, assistance, or more information, feel free to contact us.
      </Text>

      {requestSent ? (
        <Text color={"white"} textAlign={"center"} paddingY={10}>
          Your contact request has been sent!
        </Text>
      ) : (
        <Box py={"10"} rounded={"full"}>
          <Stack
            direction={["column", "row"]}
            p={"2"}
            bgColor={["none", "black"]}
            rounded={"full"}
            spacing={["4", "0"]}
          >
            <FormControl
              w={"300px"}
              rounded={"full"}
              bgColor={["black", "none"]}
            >
              <Input
                type="text"
                placeholder={"Your Email / Phone Number"}
                rounded={"full"}
                color={"white"}
                border={"none"}
                textAlign={["center", "left"]}
                value={input}
                onChange={(e) => setInput(e.target.value)}
              />
            </FormControl>

            <Button
              px={"10"}
              colorScheme="brand"
              color={"black"}
              rounded={"full"}
              onClick={handleContactRequest}
              isLoading={loading}
              loadingText={"Sending..."}
            >
              CONTACT ME
            </Button>
          </Stack>
        </Box>
      )}

      <Text color={"white"} textAlign={"center"}>
        or email:{" "}
        <Link
          href="mailto:admin@thehiveapp.online"
          color={"yellow.300"}
          fontWeight={"semibold"}
        >
          admin@thehiveapp.online
        </Link>
      </Text>
    </VStack>
  );
};

export default ContactRequest;
