import { FC } from "react";

import {
  Box,
  VStack,
  Heading,
  Text,
  Button,
  Stack,
  Link,
} from "@chakra-ui/react";

import { IoLogoGooglePlaystore, IoLogoAppleAppstore } from "react-icons/io5";

interface DownloadBannerProps {}

const DownloadBanner: FC<DownloadBannerProps> = () => {
  return (
    <Box pt={["16", "32"]} pb={["20", "40"]}>
      <Stack
        direction={["column", "row"]}
        w={"100vw"}
        py={"12"}
        px={["5", "20", "64"]}
        justify={"space-between"}
        align={"center"}
        bg={"black"}
      >
        <VStack
          w={["full", "70%", "45%"]}
          justify={["center", "flex-start"]}
          align={["center", "flex-start"]}
        >
          <Heading size={"2xl"} color={"white"} textAlign={["center", "left"]}>
            DOWNLOAD THE HIVE
          </Heading>
          <Text color={"white"} textAlign={["center", "left"]}>
            Ready to experience the future of business discovery? Download The
            Hive app now and step into a world where connections are made
            effortlessly.
          </Text>
        </VStack>

        <VStack
          pt={["10", "0"]}
          pl={["0", "10"]}
          spacing={"44"}
          justify={"space-between"}
          align={"flex-start"}
        >
          <VStack spacing={"5"}>
            <Link
              href="#"
              _hover={{
                textDecoration: "none",
              }}
              target="_blank"
            >
              <Button
                minW={"240px"}
                py={"6"}
                px={"10"}
                bg="white"
                color="black"
                leftIcon={<IoLogoGooglePlaystore size={"20"} />}
                iconSpacing={"8"}
                justifyContent={"start"}
              >
                <VStack justify={"center"} align={"flex-start"} spacing={0}>
                  <Text fontSize={"xx-small"} fontWeight={"light"}>
                    AVAILABLE ON
                  </Text>
                  <Text>GOOGLE PLAY</Text>
                </VStack>
              </Button>
            </Link>

            <Link
              href="#"
              _hover={{
                textDecoration: "none",
              }}
              target="_blank"
            >
              <Button
                minW={"240px"}
                py={"6"}
                px={"10"}
                bg="white"
                color="black"
                leftIcon={<IoLogoAppleAppstore size={"20"} />}
                iconSpacing={"8"}
                justifyContent={"start"}
              >
                <VStack justify={"center"} align={"flex-start"} spacing={0}>
                  <Text fontSize={"xx-small"} fontWeight={"light"}>
                    AVAILABLE ON
                  </Text>
                  <Text>APP STORE</Text>
                </VStack>
              </Button>
            </Link>
          </VStack>
        </VStack>
      </Stack>
    </Box>
  );
};

export default DownloadBanner;
