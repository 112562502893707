import { FC } from "react";

import {
  VStack,
  Image,
  Heading,
  Highlight,
  Text,
  Box,
  Stack,
} from "@chakra-ui/react";

interface FeatureSearchProps {}

const FeatureSearch: FC<FeatureSearchProps> = () => {
  return (
    <Stack
      direction={["column", "row"]}
      w={"100vw"}
      pt={["14", "28"]}
      pb={["8", "0"]}
      justify={"center"}
      align={["center", "flex-start"]}
      spacing={["0", "32"]}
      bgImg={["none", "url('/media/the_hive_search_banner.png')"]}
      bgPos="bottom"
      bgRepeat="no-repeat"
      bgSize={"contain"}
    >
      <Box w={"320px"}>
        <Image
          src="/media/the_hive_search_preview.png"
          alt="The Hive Header Image"
        />
      </Box>

      <VStack
        w={["92%", "32%"]}
        justify={["center", "flex-start"]}
        align={["center", "flex-start"]}
      >
        <Heading
          mt={["5", "14"]}
          mb={"5"}
          size={"2xl"}
          color={"white"}
          textAlign={["center", "left"]}
        >
          <Highlight
            query="THE HIVE"
            styles={{ color: "white", fontSize: "32px" }}
          >
            SEARCH - THE HIVE
          </Highlight>
        </Heading>

        <Text color={"white"} textAlign={["center", "left"]}>
          Effortlessly find businesses in your area or fine-tune your search to
          your preferred location. The Hive's advanced search features ensure
          that you connect with the right businesses for your needs.
        </Text>
      </VStack>
    </Stack>
  );
};

export default FeatureSearch;
