import { Box, HStack, Image, VStack } from "@chakra-ui/react";
import axios from "axios";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Footer from "../components/Footer";
import { API_BASE_URL } from "../config/env";
import "./app.css";

const Dashboard = () => {
  const { token } = useParams();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);
  const [account, setAccount] = useState<any>({});
  const [listings, setListings] = useState<any>([]);
  const [payouts, setPayouts] = useState<any>([]);

  const fetchAffiliate = async () => {
    try {
      const response = await axios.post(
        `${API_BASE_URL}authentication/fetch-affiliate.php`,
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
          token: token,
        }
      );

      if (response.data.status === "success") {
        setAccount(response.data.affiliate_account);

        let affiliateID = response.data.affiliate_account.affiliate_id;

        try {
          const responseListing = await axios.post(
            `${API_BASE_URL}affiliate-accounts/fetch-affiliate-listings.php`,
            {
              headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
              },
              token: token,
              affiliate_id: affiliateID,
            }
          );

          if (responseListing.data.status === "success") {
            setListings(responseListing.data.affiliate_listings);

            try {
              const responsePayouts = await axios.post(
                `${API_BASE_URL}affiliate-accounts/fetch-affiliate-payouts.php`,
                {
                  headers: {
                    "Content-Type": "application/json",
                    Accept: "application/json",
                  },
                  token: token,
                  affiliate_id: affiliateID,
                }
              );
              if (responsePayouts.data.status === "success") {
                setPayouts(responsePayouts.data.payouts);
                setLoading(false);
              }
            } catch (error) {
              setLoading(false);
            }
          }
        } catch (error) {
          setLoading(false);
        }
      } else {
        navigate("/");
      }
    } catch (error) {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchAffiliate();
  }, []);

  return (
    <div
      style={{
        backgroundColor: "#1A1A1A",
        height: "100vh",
      }}
    >
      <VStack bg={"#1A1A1A"}>
        <HStack
          w={"full"}
          py={"4"}
          justify={"space-evenly"}
          align={"center"}
          bg={"#1A1A1A"}
        >
          <Box w={"200px"} maxW={"100vw"}>
            <Image src="/media/the_hive_logo.png" alt="The Hive Logo" />
          </Box>
        </HStack>

        <div className="heading">
          <div>Hello, {account?.full_name ?? ""}</div>
        </div>

        <HStack w={"full"} height={"auto"} bg={"#1A1A1A"} p={10}>
          {loading ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
              }}
            >
              <div className="loader"></div>
            </div>
          ) : (
            <div className="main-card">
              <div className="card">
                <div className="count"> {listings.length}</div>
                <div className="description">Linked Accounts</div>
              </div>
              <div className="card">
                <div className="count">
                  R
                  {(
                    Math.round(
                      payouts.reduce((a: any, c: any) => a + c.amount, 0) * 100
                    ) / 100
                  ).toFixed(2)}
                </div>
                <div className="description">Total Earnings</div>
              </div>
              <div className="card">
                <div className="count">
                  R{(Math.round(account.payout_due * 100) / 100).toFixed(2)}
                </div>
                <div className="description">due on next payout</div>
              </div>
            </div>
          )}
        </HStack>

        {/* <Footer /> */}
      </VStack>
    </div>
  );
};

export default Dashboard;
