import axios from "axios";
import { API_BASE_URL } from "../config/env";

export default axios.create({
  withCredentials: false,
  baseURL: API_BASE_URL,
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
  },
});

// baseURL: "https://thehive.insomnium.co.za/server/api/",
