import { FC } from "react";

import {
  VStack,
  Heading,
  Highlight,
  Image,
  HStack,
  Button,
  Text,
  Stack,
  Link,
} from "@chakra-ui/react";

import { IoLogoGooglePlaystore, IoLogoAppleAppstore } from "react-icons/io5";

interface HeaderProps {}

const Header: FC<HeaderProps> = () => {
  return (
    <VStack
      w={"100vw"}
      pb={"10"}
      bgImg={["none", "none", "url('/media/the_hive_header_background_2.png')"]}
      bgPos="bottom"
      bgRepeat="no-repeat"
      bgSize={"contain"}
    >
      <Heading
        mt={"14"}
        mb={"5"}
        size={["2xl", "4xl"]}
        color={"white"}
        textAlign={"center"}
      >
        <Highlight query="DISCOVER" styles={{ color: "#FFEE5B" }}>
          THE NEW WAY TO DISCOVER
        </Highlight>
      </Heading>

      <Stack
        direction={["column", "column", "column", "row"]}
        w={"680px"}
        p={["8", "0"]}
        maxW={"100vw"}
        justify={"center"}
        align={["center", "flex-start"]}
      >
        <Image src="/media/the_hive_header.png" alt="The Hive Header Image" />

        <Stack
          direction={["column", "row", "column"]}
          pt={"20"}
          pl={["0", "10"]}
          spacing={["10", "14", "44"]}
          justify={"space-between"}
          align={"flex-start"}
        >
          <VStack
            w={"full"}
            spacing={"5"}
            justify={"center"}
            align={["center", "flex-start"]}
          >
            <Link
              href="#"
              _hover={{
                textDecoration: "none",
              }}
              target="_blank"
            >
              <Button
                minW={"240px"}
                py={"6"}
                px={"10"}
                bg="white"
                color="black"
                leftIcon={<IoLogoGooglePlaystore size={"20"} />}
                iconSpacing={"8"}
                justifyContent={"start"}
              >
                <VStack justify={"center"} align={"flex-start"} spacing={0}>
                  <Text fontSize={"xx-small"} fontWeight={"light"}>
                    AVAILABLE ON
                  </Text>
                  <Text>GOOGLE PLAY</Text>
                </VStack>
              </Button>
            </Link>

            <Link
              href="#"
              _hover={{
                textDecoration: "none",
              }}
              target="_blank"
            >
              <Button
                minW={"240px"}
                py={"6"}
                px={"10"}
                bg="white"
                color="black"
                leftIcon={<IoLogoAppleAppstore size={"20"} />}
                iconSpacing={"8"}
                justifyContent={"start"}
              >
                <VStack justify={"center"} align={"flex-start"} spacing={0}>
                  <Text fontSize={"xx-small"} fontWeight={"light"}>
                    AVAILABLE ON
                  </Text>
                  <Text>APP STORE</Text>
                </VStack>
              </Button>
            </Link>
          </VStack>

          <Heading
            w={["auto", "auto", "360px"]}
            color={["white", "white", "black"]}
            fontSize={["28", "32", "40"]}
            fontWeight={"semibold"}
            textAlign={["center", "left"]}
          >
            Download our mobile app & start searching, sharing and earning
            today.
          </Heading>
        </Stack>
      </Stack>
    </VStack>
  );
};

export default Header;
