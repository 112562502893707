import { FC } from "react";

import { Center, Heading, Text } from "@chakra-ui/react";

const PageNotFound: FC = () => {
  return (
    <Center bg={"#1A1A1A"} color={"white"} w={"100vw"} h={"100vh"}>
      <Heading size={"4xl"}>404</Heading>

      <Text ml={"4"} size={"md"}>
        The page you are looking for does not exist.
      </Text>
    </Center>
  );
};

export default PageNotFound;
