import { FC, useEffect, useState } from "react";

import { VStack, Heading, Text, Stack } from "@chakra-ui/react";
import { fetchSubscriptionAmount } from "../api/paymentEndpoints";

interface AdvertisementBannerProps {}

const AdvertisementBanner: FC<AdvertisementBannerProps> = () => {
  const [subscriptionAmount, setSubscriptionAmount] = useState(0);

  useEffect(() => {
    fetchSubscriptionAmount()
      .then((response) => {
        if (response.data.subscription_amount) {
          setSubscriptionAmount(response.data.subscription_amount);
        }
      })
      .catch(console.log);
  }, []);

  return (
    <Stack
      direction={["column", "column", "row"]}
      w={"100vw"}
      py={["14", "20", "20"]}
      px={["8", "8", "24"]}
      justify={"space-between"}
      align={"center"}
      bg={"black"}
      spacing={["10", "10", "0"]}
    >
      <VStack
        w={["full", "full", "52%"]}
        justify={["center", "center", "flex-start"]}
        align={["center", "center", "flex-start"]}
      >
        <Heading
          size={"xl"}
          color={"white"}
          textAlign={["center", "center", "left"]}
        >
          Advertise your Business on The Hive
        </Heading>
        <Text color={"white"} textAlign={["center", "center", "left"]}>
          Download The Hive app and showcase your business with a personalized
          touch. Upload your business card with unique links to your social
          media accounts, giving your audience a direct route to your online
          presence.
        </Text>
      </VStack>

      <Text
        fontSize={["5xl", "6xl", "8xl"]}
        fontWeight={"bold"}
        color={"white"}
      >
        R{subscriptionAmount.toFixed(2)} pm
      </Text>
    </Stack>
  );
};

export default AdvertisementBanner;
