import axios from "./axios";

/*
 * Payment Endpoint:
 * Fetch Subscription Amount
 */

interface FetchSubscriptionAmountRes {
  data: {
    subscription_amount: number;
  };
}

export async function fetchSubscriptionAmount(): Promise<FetchSubscriptionAmountRes> {
  return axios.post("payments/fetch-subscription-amount.php");
}
