import React from "react";
import ReactDOM from "react-dom/client";

import { BrowserRouter } from "react-router-dom";

import { ChakraProvider } from "@chakra-ui/react";
import { extendTheme } from "@chakra-ui/react";

import Application from "./Application";

import "@fontsource/iceland";

const theme = extendTheme({
  colors: {
    brand: {
      500: "#FFEE5B",
      600: "#D1C349",
    },
  },
  fonts: {
    heading: `'Iceland', cursive`,
  },
});

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
  <React.StrictMode>
    <BrowserRouter>
      <ChakraProvider theme={theme}>
        <Application />
      </ChakraProvider>
    </BrowserRouter>
  </React.StrictMode>
);
