import { FC } from "react";

import { Center, Text } from "@chakra-ui/react";

interface IntroductionProps {}

const Introduction: FC<IntroductionProps> = () => {
  return (
    <Center w={"100vw"} py={["14", "24"]} bg={""}>
      <Text
        w={["92%", "54%"]}
        color={"white"}
        fontSize={"16px"}
        textAlign={"center"}
      >
        Welcome to The Hive, where business discovery meets the future. We're
        not just a directory; we're the new way to explore the buzzness world.
        Simplifying your search for businesses worldwide, The Hive is designed
        to be your go-to platform for effortless connections and promotions.
      </Text>
    </Center>
  );
};

export default Introduction;
