import { FC } from "react";

import { Text, Link, HStack, Stack } from "@chakra-ui/react";

interface FooterProps {}

const Footer: FC<FooterProps> = () => {
  return (
    <Stack
      direction={["column", "row"]}
      w={"100vw"}
      py={"5"}
      px={"24"}
      justify={"space-between"}
      align={"center"}
      bgColor={"black"}
      spacing={["5", "0"]}
    >
      <Text color={"white"}>
        Copyright @ {new Date().getFullYear()} The Hive
      </Text>

      <Stack
        direction={["column", "row"]}
        spacing={["2", "8"]}
        justify={"center"}
        align={"center"}
      >
        <Link
          href="/privacy-policy.html"
          target="_blank"
          color={"white"}
          fontWeight={"semibold"}
        >
          Privacy Policy
        </Link>
        <Link
          href="/terms-and-conditions.html"
          target="_blank"
          color={"white"}
          fontWeight={"semibold"}
        >
          Terms & Conditions
        </Link>
      </Stack>
    </Stack>
  );
};

export default Footer;
