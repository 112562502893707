import { FC } from "react";

import {
  VStack,
  Image,
  Heading,
  Highlight,
  Text,
  Box,
  Stack,
} from "@chakra-ui/react";

interface FeaturePromoteProps {}

const FeaturePromote: FC<FeaturePromoteProps> = () => {
  return (
    <Stack
      direction={["column", "row"]}
      w={"100vw"}
      pt={["14", "28"]}
      pb={["8", "0"]}
      justify={"center"}
      align={["center", "flex-start"]}
      spacing={["0", "32"]}
      bgImg={["none", "url('/media/the_hive_promote_banner.png')"]}
      bgPos="bottom"
      bgRepeat="no-repeat"
      bgSize={"contain"}
    >
      <Box w={"320px"}>
        <Image
          src="/media/the_hive_promote_preview.png"
          alt="The Hive Header Image"
        />
      </Box>

      <VStack
        w={["92%", "32%"]}
        justify={["center", "flex-start"]}
        align={["center", "flex-start"]}
      >
        <Heading
          mt={["5", "14"]}
          mb={"5"}
          size={"2xl"}
          color={"white"}
          textAlign={["center", "left"]}
        >
          <Highlight
            query="THE HIVE"
            styles={{ color: "white", fontSize: "32px" }}
          >
            PROMOTE - THE HIVE
          </Highlight>
        </Heading>

        <Text color={"white"} textAlign={["center", "left"]}>
          Become a worker bee and elevate your experience. Recruit businesses to
          join The Hive and generate your own passive income by earning half the
          subscription fee. It's not just a directory; it's an opportunity to
          thrive.
        </Text>
      </VStack>
    </Stack>
  );
};

export default FeaturePromote;
