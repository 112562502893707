import { FC } from "react";

import { HStack, Box, Image, Link, Button } from "@chakra-ui/react";

const NavigationBar: FC = () => {
  return (
    <HStack
      w={"full"}
      py={"4"}
      justify={"space-evenly"}
      align={"center"}
      bg={"#1A1A1A"}
    >
      <Box w={"200px"} maxW={"100vw"}>
        <Image src="/media/the_hive_logo.png" alt="The Hive Logo" />
      </Box>

      <HStack
        display={["none", "none", "block"]}
        color={"white"}
        justify={"space-between"}
        align={"center"}
      >
        <Link href="#about" fontWeight={"semibold"} paddingX={"4"}>
          ABOUT
        </Link>

        <Link href="#features" fontWeight={"semibold"} paddingX={"4"}>
          FEATURES
        </Link>

        <Link href="#contact" fontWeight={"semibold"} paddingX={"4"}>
          CONTACT
        </Link>
      </HStack>

      <Link
        href="#"
        _hover={{
          textDecoration: "none",
        }}
        target="_blank"
      >
        <Button
          display={["none", "none", "block"]}
          colorScheme="brand"
          color={"black"}
        >
          JOIN THE HIVE
        </Button>
      </Link>
    </HStack>
  );
};

export default NavigationBar;
