import { FC } from "react";

import { VStack } from "@chakra-ui/react";

import NavigationBar from "../components/NavigationBar";
import Header from "../components/Header";
import Introduction from "../components/Introduction";
import AdvertisementBanner from "../components/AdvertisementBanner";
import FeatureSearch from "../components/FeatureSearch";
import FeatureJoin from "../components/FeatureJoin";
import FeaturePromote from "../components/FeaturePromote";
import DownloadBanner from "../components/DownloadBanner";
import ContactRequest from "../components/ContactRequest";
import Footer from "../components/Footer";

const LandingPage: FC = () => {
  return (
    <VStack bg={"#1A1A1A"}>
      <NavigationBar />
      <Header />

      <div id="about">
        <Introduction />
      </div>

      <AdvertisementBanner />

      <div id="features">
        <FeatureSearch />
        <FeatureJoin />
        <FeaturePromote />
      </div>

      <DownloadBanner />

      <div id="contact">
        <ContactRequest />
      </div>

      <Footer />
    </VStack>
  );
};

export default LandingPage;
